import React from 'react';
import RESUME from '../../assets/Jonas G - Resume (Web Copy).pdf';
import { AiFillFilePdf } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import './header.css';

const CTA = () => {
  return (
    <div className='cta'>
      <a href="https://drive.google.com/file/d/1Wb8HZy6WdZhepuhHY5Wx7nTxKMqHCEzz/view?usp=sharing" download className='btn' target='_blank'>
        <AiFillFilePdf className='header-icon' /> Resume
      </a>
      <a href="#contact" className='btn'>
        <HiOutlineMail className='header-icon' /> Let's Talk
      </a>
    </div>
  );
};

export default CTA;
