import React from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineUser } from 'react-icons/ai';
import { BiBook } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import { AiOutlineMessage } from 'react-icons/ai';
import { BiLibrary } from 'react-icons/bi';
import { useState } from 'react';
import {HiOutlineWrenchScrewdriver} from 'react-icons/hi2';


const Nav = () => {
  const [activeNav, setActiveNav] = useState('');

  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>
        <AiOutlineHome />
        <span className="nav__label">Home</span> 
      </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
        <AiOutlineUser />
        <span className="nav__label">About</span> 
      </a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
        <HiOutlineWrenchScrewdriver />
        <span className="nav__label">Skills</span> 
      </a>
      <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}>
        <BiLibrary />
        <span className="nav__label">Portfolio</span> 
      </a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
        <AiOutlineMessage />
        <span className="nav__label">Contact</span> 
      </a>
    </nav>
  );
};

export default Nav;