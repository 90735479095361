import React from 'react' 
import './about.css'
import ME from '../../assets/me-about.jpg'


const About = () => {
  return (
    <section id='about'>
      <h4>Get To Know</h4>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          {/*
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>200+ Worldwide</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>80+ Projects</small>
            </article>
          </div>
          */}

          <p>
            I am a third-year UBC Mechanical Engineering student interested in mechanical design, composite materials, and manufacturing. I am also a member of the Formula UBC Racing design team, where I serve as the lead of our Materials and Manufacturing subteam (MnMs for short). On this team, we design and build our formula student car from scratch every year, and compete at FSAE Michigan. I'm always working on projects both in and out of school - visit the portfolio section to learn more. In my (rare) free time, I love being outside, whether I'm hiking through B.C.'s backcountry, snowboarding, cycling, or playing basketball. No matter where I am, you're sure to find me with my camera - you can see my photography <a href="https://www.instagram.com/jonas.photograff/" target='_blank'>@jonas.photograff</a> on Instagram.
          </p>

          {/*<a href="#contact" className='btn btn-primary'>Let's Talk</a>*/}
        </div>
      </div>
    </section>
  )
}

export default About