import React from 'react';
import './experience.css';
import {BiCheck} from 'react-icons/bi'

const Experience = () => {
  return (
    <section id='experience'>
      <h4>What I Offer</h4>
      <h2>Technical Skills</h2>

      <div className="container experience__container">
        <article className='experience'>
          <div className="experience__head">
            <h3>Mechanical Design</h3>
          </div>

          <ul className='experience__list'>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>SolidWorks CAD & FEA</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Abaqus FEA</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>DFM & DFA</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Material and Fastener Selection</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Test Design</p>
            </li>
          </ul>
        </article>
        { /*########## END OF SOFTWARE ##########*/ }
        <article className='experience'>
          <div className="experience__head">
            <h3>Manufacturing & Materials</h3>
          </div>

          <ul className='experience__list'>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>HSMWorks CAM, CNC Machining</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Waterjet & Laser Cutting</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Carbon Fibre Composites & Testing</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Mould Design</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>FDM 3D Printing</p>
            </li>
          </ul>
        </article>
        {/*### END OF PART 2 ###*/}
        <article className='experience'>
          <div className="experience__head">
            <h3>Software & Electronics</h3>
          </div>

          <ul className='experience__list'>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Python</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Arduino, C</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>MATLAB</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>Soldering</p>
            </li>
            <li>
              <BiCheck className='experience__list-icon'/>
              <p>HTML, CSS</p>
            </li>
          </ul>
        </article>
        {/*### END OF PART 2 ###*/}
      </div>
    </section>
  );
}

export default Experience;
