import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/steering wheel.jpg'
import IMG2 from '../../assets/223 Exploded CAD.png'
import IMG3 from '../../assets/Maglev.jpg'
import IMG4 from '../../assets/coin rings 1.JPG'
import IMG5 from '../../assets/Excel Sim.png'
import IMG6 from '../../assets/elevator down.gif'
const portfolio = () => {
  return (
    <section id='portfolio'>
      <h4>My Recent Work</h4>
      <h2>Project Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
            <h3>Carbon Fibre Steering Wheel (FUBC)</h3>
            <p>I piloted manufacturing design of Formula UBC's first ever F1-inspired carbon fibre steering wheel, with integrated dash and controls</p>
            <div className="portfolio__item-cta">
              <a href="https://docs.google.com/document/d/1687DuzD0R-RJwdw1lQOWe2Z2i_AQOkpG-uIkvML39Io/edit?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
            <h3>2D Moon Lander Vehicle (MECH 223)</h3>
            <p>A mechatronic vehicle which uses a custom photogate sensor to track live positional and movement data for calculating launch of a probe</p>
            <div className="portfolio__item-cta">
              <a href="https://drive.google.com/file/d/1bbih-WqxO22pgWrUB4x7HyURk3-p8Ydq/view?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
            <h3>Magnetic Levitation Project (MECH 220)</h3>
            <p>A magnetic levitation model of the UBC Engineering cairn - fabricated, machined, soldered, and assembled from scratch</p>
            <div className="portfolio__item-cta">
              <a href="https://docs.google.com/document/d/1vPO3EYbcdlMByZJIBB4ag91-kONurZD-kd6jWilnbEM/edit?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
            <h3>Custom Coin Ring Business</h3>
            <p>A small business based out of my bedroom, where I made customized rings from a variety of worldwide historic coins</p>
            <div className="portfolio__item-cta">
              <a href="https://docs.google.com/document/d/1qKx7vA7AxEhqgxuk5MEajGmNboG4KHJYg25wYd-pXhc/edit?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG5} alt="" />
          </div>
            <h3>Excel Rainwater Harvesting System Simulation (APSC 101)</h3>
            <p>A 5-year system forecast simulation built in Microsoft Excel, taking dozens of user inputs to predict the success of a design for a rainwater harvesting system. </p>
            <div className="portfolio__item-cta">
              <a href="https://docs.google.com/document/d/1FAZcb8_ONMOd3y6zevzHZHJW39BfNdfqYx_L2OqnlCA/edit?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG6} alt="" />
          </div>
            <h3>Mechanical Elevator Bed</h3>
            <p>After moving into a room that was meant to be an office, I built this elevator bed to optimize space (and to have some fun with a large summer project!)</p>
            <div className="portfolio__item-cta">
              <a href="https://docs.google.com/document/d/1Q2OzV6fHv76r1UpAq253yAdFLuIBu5zH91GE8dfrzTo/edit?usp=sharing" className='btn btn-primary' target='_blank'>Learn More</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default portfolio