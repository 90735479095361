import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {AiFillCamera} from 'react-icons/ai'


const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/jonasgraff/" target="_blank"><BsLinkedin/></a>
        <a href="https://www.instagram.com/jonas.photograff/" target="_blank"><AiFillCamera/></a>
        
    </div>
  )
}

export default HeaderSocials